import { template as template_2e9610fd3cc14f94982253a48fa59f6d } from "@ember/template-compiler";
const FKControlMenuContainer = template_2e9610fd3cc14f94982253a48fa59f6d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_f1a5a4a0b8724879a4a848d3247cc8da } from "@ember/template-compiler";
const FKLabel = template_f1a5a4a0b8724879a4a848d3247cc8da(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_97f4288a0a34457f950a68fbfed9fa04 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_97f4288a0a34457f950a68fbfed9fa04(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
